import superagent from 'superagent';
import { getSearchString } from 'utils/url';
import { getDefaultLanguage } from 'utils/translation';

const SOMETHING_WENT_WRONG = 'Something went wrong!';

const host = 'https://api.maps.menu';
const adminSite = "https://dash.maps.menu";
const cookieDomain = "maps.menu";
let profile = null;
let ownerId = null;


const setProfile = (newProfile) => {
  profile = newProfile;
};

const setOwner = (newOwnerId) => {
  ownerId = newOwnerId;
};

export const getInvite = () => {
  return getSearchString(window.location.search, 'invid');
};

export const getRef = () => {
  return getSearchString(window.location.search, 'ref');
};


export const getAdminSite = (invId) => {
  if (invId) {
    return adminSite + "?invitationId=" + invId;
  }
  else {
    return adminSite;
  }
};

export const getCookieDomain = () => {
  return cookieDomain;
};

const responseBody = (res) => {
  if (res?.body)
    return res.body;
  return res;
}

const handleError = (e) => {
  console.error(SOMETHING_WENT_WRONG, e);

  try {
    var errors = !e.response?.text ? null : JSON.parse(e.response.text)?.errors;
    return !errors ? null : { errors };
  }
  catch (err) {
    console.log(err);
    return null;
  }
};

const requests = {
  get: (url, params) => superagent.get(`${host}${url}`).set('X-OwnerId', ownerId).query(params)
    .catch(handleError)
    .then(responseBody),
  post: (url, params) => superagent.post(`${host}${url}`).set('X-OwnerId', ownerId).send(params)
    .catch(handleError)
    .then(responseBody),
  put: (url, params) => superagent.put(`${host}${url}`).set('X-OwnerId', ownerId).send(params)
    .catch(handleError)
    .then(responseBody),
  del: (url, params) => superagent.del(`${host}${url}`).set('X-OwnerId', ownerId).send(params)
    .catch(handleError)
    .then(responseBody)
};

const API = {
  updateProfile: (value) => {
    setProfile(value);
  },
  updateOwner: (value) => {
    setOwner(value);
  },
  getData: () => requests.get(`/api/profiles/pages/public/${profile}`),
  register: (data) => requests.post('/api/users/register', { ...data, lang: getDefaultLanguage() }),
  sendOrder: (data) => requests.post(`/api/profiles/${profile}/preorders`, data),
};

export default API;