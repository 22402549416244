import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { trackWindowScroll } from 'react-lazy-load-image-component';
import { useCookies } from 'react-cookie';
import StoryDrink from 'components/StoriesDrinks/StoryDrink';
import SearchComponent from 'components/SearchComponent';
import { __ } from 'utils/translation';
import { CATALOG_DRINK_FILTER, CATALOG_SEARCH } from 'constants/actions';
import addedMenu from 'images/drinks.jpg';
import './index.scss';
import 'components/StoriesDrinks/index.scss';


const StoriesDrinks = ({ data, profile, scrollPosition }) => {
  const [cookies] = useCookies();
  const { storiesDrinks = [] } = useSelector((state) => state.config.data);
  const { catalogDrinks = [] } = useSelector((state) => state.config.data);
  const { storyDrinkGuid, storyDrinkGuidSelected, search } = useSelector((state) => state.config);

  const dispatch = useDispatch();

  useEffect(() => {
    if (catalogDrinks.length > 0) {
      const stories0 = storiesDrinks[0];
      const storyDrinkId = stories0.guid
      if (catalogDrinks.filter(e => e.storyDrinkGuid === storyDrinkId).length > 0) {

        dispatch({ type: CATALOG_DRINK_FILTER, storyDrinkGuid: storyDrinkGuid !== storyDrinkId ? storyDrinkId : null });
  
      }
    };
  }, []);

  const handleStoryDrinkClick = (storyDrinkId) => {
    if (catalogDrinks.filter(e => e.storyDrinkGuid === storyDrinkId).length > 0) {

      dispatch({ type: CATALOG_DRINK_FILTER, storyDrinkGuid: storyDrinkGuid !== storyDrinkId ? storyDrinkId : null });

    }
  }

  const handleSearch = (value) => {
    dispatch({ type: CATALOG_SEARCH, search: value });
  }

  var inviteId = cookies[profile];
  if (inviteId === "undefined") {
    inviteId = null;
  }

  const placeholder = __("Поиск напитка");

  storiesDrinks.sort((a, b) => b.order - a.order);

  return (
    <React.Fragment>
      {catalogDrinks.length > 0 && storiesDrinks.length > 1 &&
        <div className="stories">
          {/* <SearchComponent search={search} placeholder={placeholder} onSearch={handleSearch} /> */}
          <div className="stories-picker">
            {/* <StoryDrink className={classnames([`stories-picker-item`])}
              image={addedMenu} title={__("Меню алкоголь")} /> */}
            {data.map((storyDrink, index) =>
              <StoryDrink className={classnames([`stories-picker-item`])}
                onClick={() => handleStoryDrinkClick(storyDrink.guid)}
                key={storyDrink.guid} {...storyDrink} index={index}
                selected={storyDrinkGuidSelected === storyDrink.guid}
                scrollPosition={scrollPosition} />)
            }
          </div>
        </div>
      }

    </React.Fragment>
  );
};

StoriesDrinks.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({}))
};

StoriesDrinks.defaultProps = {
  data: []
};

export default trackWindowScroll(StoriesDrinks);
