import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { Route, Switch, useParams } from 'react-router-dom';
import CookieBanner from 'react-cookie-banner';
import { Helmet } from 'react-helmet';
import Pwaupbanner from 'components/Pwaupbanner';
import Loading from 'components/common/Loading';
import Order from 'components/Order';
import Button from 'components/common/Button';
import Icon from 'components/common/Icon';

import Stories from 'components/Stories';
import StoriesTables from 'components/StoriesTables';
import StoriesDrinks from 'components/StoriesDrinks';
import StoriesCigarettas from 'components/StoriesCigarettas';
import StoriesBottles from 'components/StoriesBottles';
import StoriesSoftDrinks from 'components/StoriesSoftDrinks';
import Stories2Items from 'components/Stories2Items';
import Stories3Items from 'components/Stories3Items';
import StoriesCakes from 'components/StoriesCakes';
import StoriesSnacks from 'components/StoriesSnacks';
import StoriesDishes from 'components/StoriesDishes';
import StoriesOffers from 'components/StoriesOffers';

import CatalogItems from 'components/CatalogItems';
import CatalogDrinks from 'components/CatalogDrinks';
import CatalogCigarettas from 'components/CatalogCigarettas';
import CatalogBottles from 'components/CatalogBottles';
import CatalogSoftDrinks from 'components/CatalogSoftDrinks';
import Catalog2Items from 'components/Catalog2Items';
import Catalog3Items from 'components/Catalog3Items';
import CatalogCakes from 'components/CatalogCakes';
import CatalogSnacks from 'components/CatalogSnacks';
import CatalogDishes from 'components/CatalogDishes';
import CatalogOffers from 'components/CatalogOffers';
import CatalogTables from 'components/CatalogTables';
import CatalogEmptyItem from 'components/CatalogEmptyItem';

import Messengers from 'components/Messengers';
import Blocks from 'components/Blocks';
import Footer from 'components/Footer';
import Title from 'components/Title';
import Admin from 'components/Admin';
import Avatars from 'components/Avatars';
import AvatarsApp from 'components/AvatarsApp';
import GoogleMaps from 'components/GoogleMaps';
import Reviews from 'components/Reviews';
import ReviewsOnly from 'components/ReviewsOnly';
import SocialSharingButtons from "components/SocialSharingButtons";
import API from 'utils/api';
import { getSearchParams } from 'utils/url';
import GoogleAnalytics from 'components/GoogleAnalytics';
import { __ } from 'utils/translation';


import { CONFIG_LOAD } from 'constants/actions';
// import { Button } from '@material-ui/core';

const App = () => {
  const dispatch = useDispatch();
  const [typeStory, setTypeStory] = useState('all-dish');
  const { profile } = useParams();
  const { data = {}, dataFiltered = null } = useSelector((state) => state.config);
  let helmetTitle = "For google maps menu";
  let helmetDescription = __("Меню онлайн: посмотрите наши блюда и напитки, забронируйте столик и посмотрите карту Google, получите комплимент от ресторана за отзыв в нашем инстаграм")


  useEffect(() => {
    API.updateProfile(profile);

    dispatch({ type: CONFIG_LOAD, profile: profile });
  }, []); // eslint-disable-line

  const { currentTheme } = useSelector((state) => state.config);

  if (!data) {
    return <Loading />;
  }

  if (data.name) {
    helmetTitle = data.name
  };

  if (data.name) {
    helmetDescription = `${data.name} ${data.title} ${data.description} ${__("Меню онлайн: посмотрите наши блюда и напитки, забронируйте столик и посмотрите карту Google, получите комплимент от ресторана за отзыв в нашем инстаграм")}`
  };

  let induClient = false;
  if ((data.settingsName === "K12QDQ3h83TdWzigpZzpr3") ||
    (data.settingsName === "wEHPoDJWZxsNq3YfikT1FN") ||
    (data.settingsName === "bHc3mxkYFVFoK1uWRVpsc5") ||
    (data.settingsName === "fWR82RDv3BDTsJurNYNCR2") ||
    (data.settingsName === "zRAuo7zGRGVEDCBH5X3a56") ||
    (data.settingsName === "A4ANuFpMpyE2xQEcQA7Vmq") ||
    (data.settingsName === "MD7Ds7sVLrjUDkRf19j4Nw")) {
    induClient = true;
  };

  let magicaClient = false;
  if (data.settingsName === "YUufK5QXPD8aSLat5E6fht") {
    magicaClient = true;
  };

  let delidonnaClient = false;
  if (data.settingsName === "AgYhTPeyBQNNiovYKLM9aj") {
    delidonnaClient = true;
  };



  const nameTheme = currentTheme.name;

  const styles = {
    banner: {
      postion: 'fixed',
      bottom: '155px',
      fontFamily: 'Source Sans Pro',
      height: 110,
      background: 'rgba(52, 64, 81, 0.88) url(/cookie.png) 20px 50% no-repeat',
      backgroundSize: '30px 30px',
      backgroundColor: '',
      fontSize: '14px',
      zIndex: 100000,
      fontWeight: 600
    },
    button: {
      border: '1px solid white',
      borderRadius: 4,
      height: 32,
      lineHeight: '32px',
      background: 'transparent',
      color: 'white',
      fontSize: '12px',
      fontWeight: 600,
      opacity: 1,
      right: 20,
      marginTop: -18,

    },
    message: {
      display: 'block',
      padding: '9px 67px',
      lineHeight: 1.2,
      textAlign: 'left',
      marginRight: 40,
      color: 'white'
    },
    link: {
      textDecoration: 'none',
      fontWeight: 'bold'
    }
  };

  const utmValue = data.appWaiterLink;
  const backgroundStyles = currentTheme.getBackgroundStyles();
  let params = getSearchParams(window.location.search);
  const isApp = params[utmValue] === "" || params[utmValue];

  let isIamWaiter = false;
  if (params['iamwaiter'] === "" || params['iamwaiter']) {
    isIamWaiter = true;
  }


  let isAppNew = false;
  if (data.stories.length === 0 || data.blocks.length === 0) {
    isAppNew = true;
  }
  
  
  return (
    <React.Fragment>
      <Helmet>
        <html lang="en" amp />
        <title>{helmetTitle}</title>
        <meta name="description" content={helmetDescription} />
        <link rel="canonical" href="https://maps.menu" />
        <meta property="og:site_name" content={helmetTitle} />
        <link rel="apple-touch-icon" href="https://maps.menu/logo192.png" />
        <link rel="icon" href="https://maps.menu/logo192.png" />
        <link rel="manifest" href="https://maps.menu/manifest.json" />
        <meta property="og:url" content="https://maps.menu/" />
        <meta property="og:locale" content="https://maps.menu/" />
        <meta property="og:type:profile:username" content={helmetTitle} />
        <meta property="og:type:article:published_time" content="May 01 2020 10:12 GMT" />
        <meta property="og:image" content={helmetTitle} />
        <meta property="og:image:secure_url" content="https://maps.menu/logo512.png" />
        <meta property="og:image:width" content="512" />
        <meta property="og:image:height" content="512" />
        <meta property="og:title" content={helmetTitle} />
        <meta property="og:description" content={helmetDescription} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="https://maps.menu" />
        <meta name="twitter:title" content={helmetTitle} />
        <meta name="twitter:description" content={helmetDescription} />
        <meta name="twitter:creator" content={helmetTitle} />
        <meta name="twitter:image:src" content="https://maps.menu/logo512.png/logo512.png" />
        <meta name="twitter:domain" content="https://maps.menu/logo512.png" />
        <link rel="icon" href="https://maps.menu/favicon1.ico" />
        <meta name="theme-color" content="#000000" />
        <meta name="theme-color" content="#fff" />
        <meta name="author" content="Roman Bolshiyanov bolshiyanov@gmail.com" />
        <link rel="author" href="https://instagram.com/maps.menu" />
        <meta name="date" content="May 01 2020 10:10 GMT" />
        <meta name="revisit-after" content="1 days" />
        <meta name="robots" content="all" />
        <meta property="og:type" content="website" />
        {/* <meta property="og:video" content="https://youtu.be/onoEvZUwy5o" /> */}
        <meta name="theme-color" content="#ffffff" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0,maximum-scale=1.0, user-scalable=no" />
        <meta name="msapplication-navbutton-color" content="#ffffff" />
        <meta name="apple-mobile-web-app-status-bar-style" content="#ffffff" />
        <script src="https://apps.elfsight.com/p/platform.js" defer></script>

      </Helmet>

      
          {induClient &&
            <div className="app" style={backgroundStyles}>
              <GoogleAnalytics />
                <div className="app-container">
                  <Order title={data.title} />
                  <Avatars data={data.avatars} />
                  <Stories data={dataFiltered?.stories ?? data.stories} />
                  <CatalogDrinks data={dataFiltered?.catalogDrinks ?? data.catalogDrinks} profile={profile} />
                  <Blocks data={data.blocks} />
                  <Title />
                  <Messengers />
                  <SocialSharingButtons />
                  <div className="cookie-box" >
                    <CookieBanner styles={styles}
                      message={__('Мы используем Cookies для Google analytics. Мы не собираем персональные данные')}
                      buttonMessage={__('Закрыть')}
                      link={<a href={__('https://ru.wikipedia.org/wiki/Cookie')} target="_blank">{__("Что это: COOKIES")}</a>}
                    />
                  </div>
              </div>
            </div>
          }

            {(magicaClient || delidonnaClient) &&
              <div className="app" style={backgroundStyles}>
                <GoogleAnalytics />
                <div className="app-container">
                  <Order title={data.title} />
                  <Avatars data={data.avatars} />
                  <div className="app-story-box">
                    <div className="app-story-box-picker">
                      {data.storiesDrinks.length > 0 && <Button onClick={() => setTypeStory("drinks")} className={`app-story-type-button${typeStory === "drinks" ? "-selected" : ""}`}><Icon className={classnames(['icon-40-minus-padding'])} type="cocktail" /></Button>}
                      {data.stories.length > 0 && <Button onClick={() => setTypeStory("all-dish")} className={`app-story-type-button${typeStory === "all-dish" ? "-selected" : ""}`}><Icon className={classnames(['icon-40'])} type="Utensils" /></Button>}
                    </div>
                  </div>
                  {typeStory === "drinks" &&
                    <>
                      <StoriesDrinks data={dataFiltered?.storiesDrinks ?? data.storiesDrinks} />
                      <CatalogDrinks data={dataFiltered?.catalogDrinks ?? data.catalogDrinks} profile={profile} />
                    </>
                  }
                  {typeStory === "all-dish" &&
                    <>
                      <Stories data={dataFiltered?.stories ?? data.stories} />
                      <CatalogItems data={dataFiltered?.catalogItems ?? data.catalogItems} profile={profile} />
                    </>
                  }
                  <Blocks data={data.blocks} />
                  <Title />
                  <Messengers />
                  <SocialSharingButtons />
                  <div className="cookie-box" >
                    <CookieBanner styles={styles}
                     message={__('Мы используем Cookies для Google analytics. Мы не собираем персональные данные')}
                      buttonMessage={__('Закрыть')}
                     link={<a href={__('https://ru.wikipedia.org/wiki/Cookie')} target="_blank">{__("Что это: COOKIES")}</a>}
                    />
                  </div>
                </div>
            </div>
            }

          {data.avatars.length === 0 && <ReviewsOnly/>}

          {(!induClient && !magicaClient && !delidonnaClient)  &&
            <div className="app" style={backgroundStyles}>
              <GoogleAnalytics />
              <div className="app-container">
                {!isApp &&
                <>
                  <Order title={data.title} />
                  <Avatars data={data.avatars} />

                  <div className="app-story-box">
                    <div className="app-story-box-picker">
                      {data.stories.length > 0 && <Button onClick={() => setTypeStory("all-dish")} className={`app-story-type-button${typeStory === "all-dish" ? "-selected" : ""}`}><Icon className={classnames(['icon-40'])} type="Utensils" /></Button>}
                      {data.storiesDishes.length > 0 && <Button onClick={() => setTypeStory("chief")} className={`app-story-type-button${typeStory === "chief" ? "-selected" : ""}`}><Icon className={classnames(['icon-40-minus-padding'])} type="medal" /></Button>}
                      {data.storiesOffers.length > 0 && <Button onClick={() => setTypeStory("offers")} className={`app-story-type-button${typeStory === "offers" ? "-selected" : ""}`}><Icon className={classnames(['icon-40-minus-padding'])} type="percent" /></Button>}

                      {data.storiesSnacks.length > 0 && <Button onClick={() => setTypeStory("snacks")} className={`app-story-type-button${typeStory === "snacks" ? "-selected" : ""}`}><Icon className={classnames(['icon-40-minus-padding'])} type="hamburger" /></Button>}
                      {data.storiesCakes.length > 0 && <Button onClick={() => setTypeStory("cakes")} className={`app-story-type-button${typeStory === "cakes" ? "-selected" : ""}`}><Icon className={classnames(['icon-40'])} type="iceCream" /></Button>}
                      {data.stories2Items.length > 0 && <Button onClick={() => setTypeStory("children")} className={`app-story-type-button${typeStory === "children" ? "-selected" : ""}`}><Icon className={classnames(['icon-40'])} type="baby" /></Button>}

                      {data.storiesSoftDrinks.length > 0 && <Button onClick={() => setTypeStory("softDrinks")} className={`app-story-type-button${typeStory === "softDrinks" ? "-selected" : ""}`}><Icon className={classnames(['icon-40'])} type="gulp" /></Button>}
                      {data.stories3Items.length > 0 && <Button onClick={() => setTypeStory("tea")} className={`app-story-type-button${typeStory === "tea" ? "-selected" : ""}`}><Icon className={classnames(['icon-40-plus-padding'])} type="MugHot" /></Button>}
                      {data.storiesDrinks.length > 0 && <Button onClick={() => setTypeStory("drinks")} className={`app-story-type-button${typeStory === "drinks" ? "-selected" : ""}`}><Icon className={classnames(['icon-40-minus-padding'])} type="cocktail" /></Button>}

                      {data.storiesBottles.length > 0 && <Button onClick={() => setTypeStory("bottles")} className={`app-story-type-button${typeStory === "bottles" ? "-selected" : ""}`}><Icon className={classnames(['icon-40-minus-padding'])} type="wineBottle" /></Button>}
                      {data.storiesCigarettas.length > 0 && <Button onClick={() => setTypeStory("cigarettas")} className={`app-story-type-button${typeStory === "cigarettas" ? "-selected" : ""}`}><Icon className={classnames(['icon-40-special'])} type="smoking" /></Button>}
                    </div>
                  </div>
                  {typeStory === "all-dish" &&
                    <>
                      <Stories data={dataFiltered?.stories ?? data.stories} />
                      <CatalogItems data={dataFiltered?.catalogItems ?? data.catalogItems} profile={profile} />
                    </>}
                  {typeStory === "chief" &&
                    <>
                      <StoriesDishes data={dataFiltered?.storiesDishes ?? data.storiesDishes} />
                      <CatalogDishes data={dataFiltered?.catalogDishes ?? data.catalogDishes} profile={profile} />
                    </>}
                  {typeStory === "snacks" &&
                    <>
                      <StoriesSnacks data={dataFiltered?.storiesSnacks ?? data.storiesSnacks} />
                      <CatalogSnacks data={dataFiltered?.catalogSnacks ?? data.catalogSnacks} profile={profile} />
                    </>}
                  {typeStory === "cakes" &&
                    <>
                      <StoriesCakes data={dataFiltered?.storiesCakes ?? data.storiesCakes} />
                      <CatalogCakes data={dataFiltered?.catalogCakes ?? data.catalogCakes} profile={profile} />
                    </>}
                  {typeStory === "children" &&
                    <>
                      <Stories2Items data={dataFiltered?.stories2Items ?? data.stories2Items} />
                      <Catalog2Items data={dataFiltered?.catalog2Items ?? data.catalog2Items} profile={profile} />
                    </>}

                  {typeStory === "tea" &&
                    <>
                      <Stories3Items data={dataFiltered?.stories3Items ?? data.stories3Items} />
                      <Catalog3Items data={dataFiltered?.catalog3Items ?? data.catalog3Items} profile={profile} />
                    </>}
                  {typeStory === "offers" &&
                    <>
                      <StoriesOffers data={dataFiltered?.storiesOffers ?? data.storiesOffers} />
                      <CatalogOffers data={dataFiltered?.catalogOffers ?? data.catalogOffers} profile={profile} />
                    </>}
                  {typeStory === "softDrinks" &&
                    <>
                      <StoriesSoftDrinks data={dataFiltered?.storiesSoftDrinks ?? data.storiesSoftDrinks} />
                      <CatalogSoftDrinks data={dataFiltered?.catalogSoftDrinks ?? data.catalogSoftDrinks} profile={profile} />
                    </>}
                  {typeStory === "drinks" &&
                    <>
                      <StoriesDrinks data={dataFiltered?.storiesDrinks ?? data.storiesDrinks} />
                      <CatalogDrinks data={dataFiltered?.catalogDrinks ?? data.catalogDrinks} profile={profile} />
                    </>}
                  {typeStory === "bottles" &&
                    <>
                      <StoriesBottles data={dataFiltered?.storiesBottles ?? data.storiesBottles} />
                      <CatalogBottles data={dataFiltered?.catalogBottles ?? data.catalogBottles} profile={profile} />
                    </>}
                  {typeStory === "cigarettas" &&
                    <>
                      <StoriesCigarettas data={dataFiltered?.storiesCigarettas ?? data.storiesCigarettas} />
                      <CatalogCigarettas data={dataFiltered?.catalogCigarettas ?? data.catalogCigarettas} profile={profile} />
                    </>}

                  {!isIamWaiter && <Blocks data={data.blocks} />}
                  <CatalogEmptyItem />
                  {!isIamWaiter && <Reviews />}
                  {!isIamWaiter && <Title />}
                  {!isIamWaiter && <GoogleMaps />}
                  {!isIamWaiter && <Messengers />}
                  {!isAppNew && !isIamWaiter && <SocialSharingButtons />}
                  {!isIamWaiter && <Pwaupbanner data={data.avatars} profile={profile} />}

                  <Footer />
                  <div className="cookie-box" >
                    <CookieBanner styles={styles}
                      message={__('Мы используем Cookies для Google analytics. Мы не собираем персональные данные')}
                      buttonMessage={__('Закрыть')}
                      link={<a href={__('https://ru.wikipedia.org/wiki/Cookie')} target="_blank">{__("Что это: COOKIES")}</a>}
                    />
                  </div>
                </>}
              {isApp && 
                <>
                  <AvatarsApp data={data.avatars} />
                  <StoriesTables data={dataFiltered?.storiesTables ?? data.storiesTables} />
                  <CatalogTables data={dataFiltered?.catalogTables ?? data.catalogTables} profile={profile} />
                  <Footer />
                </>
              }
            </div> 
           </div>           
          }
    </React.Fragment>
  );
};
const Router = () => (
  <Switch>
    <Route path="/:profile" component={App} />
    <Route component={Admin} />
  </Switch>
);

export default Router;