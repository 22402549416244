import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Button from 'components/common/Button';
import { __, translatedProperty } from 'utils/translation';
import copy from 'clipboard-copy';
import './index.scss';
import { getSearchParams } from 'utils/url';
import Review from 'components/Review';
import { CATALOG_ORDER } from 'constants/actions';
import ShowReview from 'components/Review/ShowReview';
import allergy_altramuces from 'images/allergy_altramuces.png';
import allergy_apio from 'images/allergy_apio.png';
import allergy_cacahuetas from 'images/allergy_cacahuetas.png';
import allergy_crustaseos from 'images/allergy_crustaseos.png';
import allergy_dioxido from 'images/allergy_dioxido.png';
import allergy_gluten from 'images/allergy_gluten.png';
import allergy_huevos from 'images/allergy_huevos.png';
import allergy_leche from 'images/allergy_leche.png';
import allergy_moluscos from 'images/allergy_moluscos.png';
import allergy_mostaza from 'images/allergy_mostaza.png';
import allergy_nuts from 'images/allergy_nuts.png';
import allergy_pescados from 'images/allergy_pescados.png';
import allergy_sesamo from 'images/allergy_sesamo.png';
import allergy_soja from 'images/allergy_soja.png';

const CatalogItemSettings = (props) => {
    const { guid, image, price, currency, number, reviews,
        allergySoja, allergyPescado, allergyMostaza, allergyMoluscos, allergyGluten, allergyCascara, allergyApio,
        allergySulfitos, allergyCrustaseos, allergyAltramuces, allergyLacteos, allergyHuevos, allergySesamo, allergyCacahuetes
    } = props;
    const dispatch = useDispatch();
    const { count, sum } = useSelector((state) => state.config.order[guid] ?? { count: 0, sum: 0 });
    const text = translatedProperty(props, "text");
    const textAlt = translatedProperty(props, "textAlt");
    const description = translatedProperty(props, "description");

    const reviewImage = (reviews?.filter(r => r.isActive) ?? []).reverse().filter(r => r.image)[0]?.image;
    const imageCard = image ?? reviewImage;

    var params = getSearchParams(window.location.search);

    let isWaiter = false;
    if (params['waiter'] === "" || params['waiter']) {
        isWaiter = true;
    };

    const handlePlus = (e) => {
        e.stopPropagation();
        dispatch({
            type: CATALOG_ORDER, guid, count: count + 1,
            sum: sum + parseFloat(price), currency
        });
    }

    const handleMinus = (e) => {
        e.stopPropagation();
        if (count === 0) {
            return;
        }
        dispatch({
            type: CATALOG_ORDER, guid, count: count - 1,
            sum: sum - parseFloat(price), currency
        });
    };

    let useAllergyButtons = false;
    if (
        (allergySoja || false) || (allergyPescado || false) || (allergyMostaza || false) || (allergyMoluscos || false) || (allergyGluten || false) ||
        (allergyCascara || false) || (allergyApio || false) || (allergySulfitos || false) || (allergyCrustaseos || false) || (allergyAltramuces || false) ||
        (allergyLacteos || false) || (allergyHuevos || false) || (allergySesamo || false) || (allergyCacahuetes || false)) {
        useAllergyButtons = true;
    };


    return (
        <React.Fragment>
            <div className="catalogItem-slider">
                <div className="catalogItem-slider__title">{text}</div>
                {textAlt && (
                    <div className="catalogItem-slider__description">{textAlt}</div>
                )}
                {imageCard && <div className="catalogItem-slider__pic"><img src={imageCard} alt={text} /></div>}
                
                
                {useAllergyButtons &&
                    <>
                        <div className="catalogItem-settings-allegy-box">
                            {allergySoja &&
                                <div className="catalogItem-settings-allegy-items-box">
                                    <div className="catalogItem-allegy-items" style={{ backgroundImage: `URL(${allergy_soja})` }} />
                                    <div className="catalogItem-settings-allegy-title">{__("Содержит Сою")}</div>
                                </div>
                            }
                            {allergyGluten &&
                                <div className="catalogItem-settings-allegy-items-box">
                                    <div className="catalogItem-allegy-items" style={{ backgroundImage: `URL(${allergy_gluten})` }} />
                                    <div className="catalogItem-settings-allegy-title">{__("Содержит глютен")}</div>
                                </div>
                            }
                            {allergyPescado &&
                                <div className="catalogItem-settings-allegy-items-box">
                                    <div className="catalogItem-allegy-items" style={{ backgroundImage: `URL(${allergy_pescados})` }} />
                                    <div className="catalogItem-settings-allegy-title">{__("Содержит Рыбу")}</div>
                                </div>
                            }
                            {allergyMostaza &&
                                <div className="catalogItem-settings-allegy-items-box">
                                    <div className="catalogItem-allegy-items" style={{ backgroundImage: `URL(${allergy_mostaza})` }} />
                                    <div className="catalogItem-settings-allegy-title">{__("Содержит горчицу")}</div>
                                </div>
                            }
                            {allergyMoluscos &&
                                <div className="catalogItem-settings-allegy-items-box">
                                    <div className="catalogItem-allegy-items" style={{ backgroundImage: `URL(${allergy_moluscos})` }} />
                                    <div className="catalogItem-settings-allegy-title">{__("Содержит моллюсков")}</div>
                                </div>
                            }
                            {allergyCascara &&
                                <div className="catalogItem-settings-allegy-items-box">
                                    <div className="catalogItem-allegy-items" style={{ backgroundImage: `URL(${allergy_nuts})` }} />
                                    <div className="catalogItem-settings-allegy-title">{__("Содержит орехи")}</div>
                                </div>
                            }
                            {allergyApio &&
                                <div className="catalogItem-settings-allegy-items-box">
                                    <div className="catalogItem-allegy-items" style={{ backgroundImage: `URL(${allergy_apio})` }} />
                                    <div className="catalogItem-settings-allegy-title">{__("Содержит сельдерей")}</div>
                                </div>
                            }
                            {allergySulfitos &&
                                <div className="catalogItem-settings-allegy-items-box">
                                    <div className="catalogItem-allegy-items" style={{ backgroundImage: `URL(${allergy_dioxido})` }} />
                                    <div className="catalogItem-settings-allegy-title">{__("Содержит диоксиды серы и сульфиты")}</div>
                                </div>
                            }
                            {allergyCrustaseos &&
                                <div className="catalogItem-settings-allegy-items-box">
                                    <div className="catalogItem-allegy-items" style={{ backgroundImage: `URL(${allergy_crustaseos})` }} />
                                    <div className="catalogItem-settings-allegy-title">{__("Содержит Ракообразных")}</div>
                                </div>
                            }
                            {allergyAltramuces &&
                                <div className="catalogItem-settings-allegy-items-box">
                                    <div className="catalogItem-allegy-items" style={{ backgroundImage: `URL(${allergy_altramuces})` }} />
                                    <div className="catalogItem-settings-allegy-title">{__("Содержит Люпины")}</div>
                                </div>
                            }
                            {allergyLacteos &&
                                <div className="catalogItem-settings-allegy-items-box">
                                    <div className="catalogItem-allegy-items" style={{ backgroundImage: `URL(${allergy_leche})` }} />
                                    <div className="catalogItem-settings-allegy-title">{__("Содержит лактозу")}</div>
                                </div>
                            }
                            {allergyHuevos &&
                                <div className="catalogItem-settings-allegy-items-box">
                                    <div className="catalogItem-allegy-items" style={{ backgroundImage: `URL(${allergy_huevos})` }} />
                                    <div className="catalogItem-settings-allegy-title">{__("Содержит яйца")}</div>
                                </div>
                            }
                            {allergySesamo &&
                                <div className="catalogItem-settings-allegy-items-box">
                                    <div className="catalogItem-allegy-items" style={{ backgroundImage: `URL(${allergy_sesamo})` }} />
                                    <div className="catalogItem-settings-allegy-title">{__("Содержит кунжутные зерна")}</div>
                                </div>
                            }
                            {allergyCacahuetes &&
                                <div className="catalogItem-settings-allegy-items-box">
                                    <div className="catalogItem-allegy-items" style={{ backgroundImage: `URL(${allergy_cacahuetas})` }} />
                                    <div className="catalogItem-settings-allegy-title">{__("Содержит арахис")}</div>
                                </div>
                            }                            
                        </div>
                    </>}
                    
                    {price && (
                    <>
                        <div className="catalogItem-slider__price">{__("Стоимость")}: {price} {currency}</div>

                        {!isWaiter &&
                            <div className="catalogItem-order-box">
                                <Button className="catalogItem-order-botton" noStyled
                                    onClick={handleMinus} >{__("Убрать")}</Button>
                                <div className="catalogItem-order-box-quantity">{count}</div>
                                <Button className="catalogItem-order-botton" noStyled
                                    onClick={handlePlus} >{__("Добавить")}</Button>
                            </div>
                        }
                    </>)}
                {number && (
                    <div className="catalogItem-slider__number">{__("Артикул")}: {number}</div>
                )}
                <Review items={[{ ...props }]} />
                {description && (
                    <>
                        <div className="catalogItem-slider__description-header">{__("Описание")}</div>
                        <div className="catalogItem-slider__description">{description}</div>
                    </>
                )}
                <br />
                <ShowReview data={props.reviews?.filter(r => r.isActive)} />
            </div>
        </React.Fragment >
    );
};

CatalogItemSettings.propTypes = {
    image: PropTypes.string,
    text: PropTypes.string,
    description: PropTypes.string,
    textEn: PropTypes.string,
    descriptionEn: PropTypes.string,
    price: PropTypes.string,
    currency: PropTypes.string,
    number: PropTypes.string
};

CatalogItemSettings.defaultProps = {
    image: '',
    text: '',
    description: '',
    textEn: '',
    descriptionEn: '',
    price: '',
    currency: '',
    number: ''
};

export default CatalogItemSettings;

