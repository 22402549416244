import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { trackWindowScroll, LazyLoadComponent } from 'react-lazy-load-image-component';
import { useCookies } from 'react-cookie';
import { uuid } from 'uuidv4';
import CatalogDrink from './CatalogDrink';
import CatalogDrinkSettings from './CatalogDrinkSettings';
import Slider from 'components/common/Slider';
import { __ } from 'utils/translation';
import { SCROLL_CATALOG_ID } from 'constants/scrolls';


import './index.scss';

const emptySettings = {
  guid: null,
  type: 'preview-text',
  text: '',
  description: '',
  image: '',
  textEn: '',
  descriptionEn: '',
};

const CatalogDrinks = ({ data, profile, scrollPosition }) => {
  const [settingsOpened, setSettingsOpened] = useState(null);
  const [catalogDrinkData, setCatalogDrinkData] = useState(emptySettings);
  const [cookies] = useCookies();
  const { active } = useSelector((state) => state.config.account);
  const { catalogDrinks = [] } = useSelector((state) => state.config.data);
  const { storiesDrinks = [] } = useSelector((state) => state.config.data);
  const { storyDrinkGuidSelected, headerDrinkGuid, dataFiltered } = useSelector((state) => state.config);

  const closeCatalogDrinksSettings = () => {
    setSettingsOpened(null);
  };

  const onOpenCatalogDrinkSettings = (catalogDrinkId) => {
    setSettingsOpened(catalogDrinkId);
  };

  var inviteId = cookies[profile];
  if (inviteId === "undefined") { 
    inviteId = null;
  }

  useEffect(() => {
    const currentCatalogDrink = data.find((catalogDrink) => catalogDrink.guid === settingsOpened);
    const settings = currentCatalogDrink || { ...emptySettings };
    if (!settings.guid)
      settings.guid = uuid();
    setCatalogDrinkData({ ...settings });
  }, [settingsOpened, data]);

  const hour = new Date().getHours();
  const checkCatalogDrink = (e) => {
    return (storyDrinkGuidSelected || (storiesDrinks?.length ?? 0) === 0)
      && (!storyDrinkGuidSelected || !e.storyDrinkGuid || e.storyDrinkGuid === storyDrinkGuidSelected)
      && !e.outOfStock
      && ((!e.timeFrom && !e.timeTo) ||
        (e.timeFrom && e.timeTo && parseInt(e.timeFrom) <= hour && parseInt(e.timeTo) >= hour) ||
        (e.timeFrom && e.timeTo && parseInt(e.timeFrom) > parseInt(e.timeTo) && (parseInt(e.timeFrom) <= hour || parseInt(e.timeTo) <= hour)) ||
        (e.timeFrom && !e.timeTo && parseInt(e.timeFrom) <= hour) ||
        (!e.timeFrom && e.timeTo && parseInt(e.timeTo) >= hour))
      && (!e.headerDrinkGuid || dataFiltered || e.headerDrinkGuid === headerDrinkGuid);
  }

  // if (!inviteId && !active) { 
  //   return null;  
  // }

  data.sort((a, b) => b.order - a.order);
  return (
    <React.Fragment>
      {catalogDrinks.length > 0 &&
        <div id={SCROLL_CATALOG_ID}>{
          data.filter(checkCatalogDrink).map((catalogDrink, index) =>
            <LazyLoadComponent key={catalogDrink.guid} scrollPosition={scrollPosition} threshold={10}>
              <CatalogDrink
                key={catalogDrink.guid}
                {...catalogDrink}
                index={index}
                scrollPosition={scrollPosition}
                onClick={catalogDrink.price || catalogDrink.number ? (() => onOpenCatalogDrinkSettings(catalogDrink.guid)) : null} />
            </LazyLoadComponent>)
        }</div>
      }
      <div className="empty-catalogItems-box"></div>
      <Slider
        opened={settingsOpened}
        onClose={closeCatalogDrinksSettings}
        onSubmit={closeCatalogDrinksSettings}
        submitTitle={__("ЗАКРЫТЬ")?.toUpperCase()}
      >
        <CatalogDrinkSettings {...catalogDrinkData} />
      </Slider>
    </React.Fragment>
  );
};

CatalogDrinks.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({}))
};

CatalogDrinks.defaultProps = {
  data: []
};

export default trackWindowScroll(CatalogDrinks);
