import React from 'react';

import PropTypes from 'prop-types';
import classnames from 'classnames';
import { select } from 'redux-saga/effects';
import menuOnline from 'images/empty.png';
import Button from 'components/common/Button';
import { LazyLoadComponent, LazyLoadImage } from 'react-lazy-load-image-component';
import { __ } from 'utils/translation';
import 'react-lazy-load-image-component/src/effects/blur.css';
import './index.scss';
import 'components/StoriesDrinks/StoryDrink/index.scss';

import { translatedProperty } from 'utils/translation';


const StoryDrink = (props) => {

    const {
        image,
        onClick,
        className,
        selected,
        index,
        scrollPosition
    } = props;
    const title = translatedProperty(props, "title")


    const storyDrink = (

        <div className={classnames(["story-picture", className])}
            onClick={onClick}
        >
            <div className="story-picture__box"
                style={{ backgroundImage: `URL(${menuOnline})` }}
            >
                <LazyLoadImage
                    src={image}
                    alt={title}
                    height={250}
                    width={250}
                    effect="blur"
                    scrollPosition={scrollPosition}
                />
                {index === 0 && <div className="story-clickme">{__("Нажми")}</div>}

            </div>
            <Button onClick={onClick} className={`story-picture-title${selected ? "-selected" : ""}`}>{title}</Button>

        </div>
    );
    return storyDrink;
}

StoryDrink.propTypes = {
    title: PropTypes.string,
    image: PropTypes.string,

};

StoryDrink.defaultProps = {
    title: '',
    image: undefined,

};

export default StoryDrink;
