import React from 'react';
import { handleActions } from 'redux-actions';

import Theme from 'utils/theme';
import Background from 'utils/background';
import ButtonColor from 'utils/buttonColor';

import {
  LOADING_ERROR,
  SET_DATA,
  UPDATE_CONFIG_DATA,

  BOOKING_DATES,
  BOOKING_DATES_CLEAR,
  BOOKING_TIMES_FROM,
  BOOKING_TIMES_FROM_CLEAR,
  BOOKING_TIMES_TO,
  BOOKING_TIMES_TO_CLEAR,
  BOOKING_PEOPLE,
  BOOKING_PEOPLE_CLEAR,

  CATALOG_SEARCH,
  CATALOG_FILTER,
  CATALOG_FILTER_HEADER,

  CATALOG_TABLE_FILTER,
  CATALOG_TABLE_FILTER_HEADER,

  CATALOG_DRINK_FILTER,
  CATALOG_DRINK_FILTER_HEADER,

  CATALOG_ORDER,
  CATALOG_ORDER_CLEAR,
  ORDER_TABLES,
  ORDER_TABLES_CLEAR,
  CATALOG_TABLE_ORDER,
  CATALOG_TABLE_ORDER_CLEAR
} from 'constants/actions';

import {
  CATALOG_HEADER  
} from 'constants/catalogTypes';

import {
  CATALOG_DRINK_HEADER
} from 'constants/catalogDrinkTypes';

import {
  CATALOG_TABLE_HEADER
} from 'constants/catalogTableTypes';

import { translatedPropertyName, supportedLanguages } from 'utils/translation';

const defaultTheme = new Theme({ name: 'Default' });
const initialState = {
  error: null,
  data: null,
  themes: [defaultTheme],
  instagramFeeds: {},
  currentTheme: defaultTheme,
  datesGuid: null,
  datesGuidSelected: null,
  timesFromGuid: null,
  timesToGuid: null,
  timesFromGuidSelected: null,
  peopleGuid: 2,
  peopleGuidSelected: 2,  
  backgrounds: [],
  buttonColors: [],
  catalogDrinks: [],
  catalogTables: [],
  storiesDrinks: [],
  storiesTables: [], 
  config: {},
  account: {},
  storyGuid : null,
  storyDrinkGuid : null,
  storyTableGuid : null,
  headerGuid: null,
  headerTableGuid: null,
  search: null,
  headerDrinkGuid: null,
  dataFiltered: null,
  storyDrinkGuidSelected: null,
  storyGuidSelected: null,
  storyTableGuidSelected: null,
  order: {},
  orderTables: {},
  playingGuid: null,
  stoppingGuid: null,
  isSubscriber: false
};

const reducer = handleActions({
  [LOADING_ERROR]: (state, { error }) => ({
    ...state,
    error
  }),

  [SET_DATA]: (state, {
    themes,
    backgrounds,
    buttonColors,
    config,
    account,
    data,
    isSubscriber
  }) => {
    const selectedTheme = themes.find((theme) => data.settings.theme === theme.name)
      || (themes[0] && { ...themes[0] })
      || { ...state.themes[0] };

    let currentTheme = new Theme(selectedTheme);

    if (data.settings.background) {
      const selectedBakground = backgrounds.find((background) => data.settings.background === background.name);
      if (selectedBakground)
        currentTheme = new Theme({ ...currentTheme, background: selectedBakground });
    }

    if (data.settings.color) {
      const selectedButtonColor = buttonColors.find((buttonColor) => data.settings.color === buttonColor.name);
      if (selectedButtonColor)
        currentTheme = new Theme({ ...currentTheme, button: selectedButtonColor });
    }

    let headerGuid = null;    
    let storyGuid = null;
    if (data.catalogItems) {
      for (let i = data.catalogItems.length - 1; i >= 0; i--) {
        let item = data.catalogItems[i];
        if (item.type === CATALOG_HEADER) {
          headerGuid = item.guid;
          storyGuid = item.storyGuid;
        } else if (item.storyGuid === storyGuid) {
          item.headerGuid = headerGuid;
        }
      }
    }    

    // let reviews = data.catalogItems.filter(ci => ci.reviews && ci.reviews.length > 0)
    //   .map(ci => ci.reviews.filter(r => r.isActive).map(r => { 
    //     return { 
    //       ...r,
    //       catalogItemId: ci.guid
    //     }
    //   })).reduce((acc, l) => acc.concat(l), []);
    // reviews.sort((a, b) => {
    //   if (a.Date > b.Date) {
    //     return -1;
    //   } 
    //   if (a.Date < b.Date) {
    //     return 1;
    //   }
    //   return 0; 
    // })

  //   let headerTableGuid = null;
  //   let storyTableGuid = null;
  //   if (data.catalogTables) {
  //     for (let i = data.catalogTables.length - 1; i >= 0; i--) {
  //       let item = data.catalogTables[i];
  //       if (item.type === CATALOG_TABLE_HEADER) {
  //         headerTableGuid = item.guid;
  //         storyTableGuid = item.storyTableGuid;
  //       } else if (item.storyTableGuid === storyTableGuid) {
  //         item.headerTableGuid = headerTableGuid;
  //       }
  //     }
  //   }

  //   let reviewsTables = data.catalogTables.filter(ci => ci.reviewsTables && ci.reviewsTables.length > 0)
  //   .map(ci => ci.reviewsTables.filter(r => r.isActive).map(r => { 
  //     return { 
  //       ...r,
  //       catalogTableId: ci.guid
  //     }
  //   })).reduce((acc, l) => acc.concat(l), []);
  // reviewsTables.sort((a, b) => {
  //   if (a.Date > b.Date) {
  //     return -1;
  //   } 
  //   if (a.Date < b.Date) {
  //     return 1;
  //   }
  //   return 0; 
  // })

    return {
      ...state,
      themes: themes.map((theme) => new Theme(theme)),
      backgrounds: backgrounds.map((background) => new Background(background)),
      buttonColors: buttonColors.map((buttonColor) => new ButtonColor(buttonColor)),
      config,
      account,
      data: {
        ...data,
        // reviews,
        // reviewsTables
      },
      currentTheme,
      error: null,
      isSubscriber
    };
  },

  [UPDATE_CONFIG_DATA]: (state, { title, description }) => ({
    ...state,
    data: {
      ...state.data,
      title,
      description
    }
  }),

  [BOOKING_DATES] : (state, { datesGuid} ) => {
    return {
      ...state,
      datesGuid,
      datesGuidSelected: datesGuid,
    }
  },

  [BOOKING_DATES_CLEAR] : (state) => {
    return {
      ...state,
      datesGuid: null
    }
  },

  [BOOKING_TIMES_FROM] : (state, { timesFromGuid } ) => {
    if (timesFromGuid === 9 * 60)
    return {
      ...state,
      timesFromGuid,      
      timesFromGuidSelected: "9:00",
    };
    if (timesFromGuid === 9.5 * 60)
    return {
      ...state,
      timesFromGuid,      
      timesFromGuidSelected: "9:30",
    };
    if (timesFromGuid === 10 * 60)
    return {
      ...state,
      timesFromGuid,      
      timesFromGuidSelected: "10:00",
    };
    if (timesFromGuid === 10.5 * 60)
    return {
      ...state,
      timesFromGuid,      
      timesFromGuidSelected: "10:30",
    };
    if (timesFromGuid === 11 * 60)
    return {
      ...state,
      timesFromGuid,      
      timesFromGuidSelected: "11:00",
    };
    if (timesFromGuid === 11.5 * 60)
    return {
      ...state,
      timesFromGuid,      
      timesFromGuidSelected: "11:30",
    };
    if (timesFromGuid === 12 * 60)
    return {
      ...state,
      timesFromGuid,      
      timesFromGuidSelected: "12:00",
    };
    if (timesFromGuid === 12.5 * 60)
    return {
      ...state,
      timesFromGuid,      
      timesFromGuidSelected: "12:30",
    };
    if (timesFromGuid === 13 * 60)
    return {
      ...state,
      timesFromGuid,      
      timesFromGuidSelected: "13:00",
    };
    if (timesFromGuid === 13.5 * 60)
    return {
      ...state,
      timesFromGuid,      
      timesFromGuidSelected: "13:30",
    };
    if (timesFromGuid === 14 * 60)
    return {
      ...state,
      timesFromGuid,      
      timesFromGuidSelected: "14:00",
    };
    if (timesFromGuid === 14.5 * 60)
    return {
      ...state,
      timesFromGuid,      
      timesFromGuidSelected: "14:30",
    };
    if (timesFromGuid === 15 * 60)
    return {
      ...state,
      timesFromGuid,      
      timesFromGuidSelected: "15:00",
    };
    if (timesFromGuid === 15.5 * 60)
    return {
      ...state,
      timesFromGuid,      
      timesFromGuidSelected: "15:30",
    };
    if (timesFromGuid === 16 * 60)
    return {
      ...state,
      timesFromGuid,      
      timesFromGuidSelected: "16:00",
    };
    if (timesFromGuid === 16.5 * 60)
    return {
      ...state,
      timesFromGuid,      
      timesFromGuidSelected: "16:30",
    };
    if (timesFromGuid === 17 * 60)
    return {
      ...state,
      timesFromGuid,      
      timesFromGuidSelected: "17:00",
    };
    if (timesFromGuid === 17.5 * 60)
    return {
      ...state,
      timesFromGuid,      
      timesFromGuidSelected: "17:30",
    };
    if (timesFromGuid === 18 * 60)
    return {
      ...state,
      timesFromGuid,      
      timesFromGuidSelected: "18:00",
    };
    if (timesFromGuid === 18.5 * 60)
    return {
      ...state,
      timesFromGuid,      
      timesFromGuidSelected: "18:30",
    };
    if (timesFromGuid === 19 * 60)
    return {
      ...state,
      timesFromGuid,      
      timesFromGuidSelected: "19:00",
    };
    if (timesFromGuid === 19.5 * 60)
    return {
      ...state,
      timesFromGuid,      
      timesFromGuidSelected: "19:30",
    };
    if (timesFromGuid === 20 * 60)
    return {
      ...state,
      timesFromGuid,      
      timesFromGuidSelected: "20:00",
    };
    if (timesFromGuid === 20.5 * 60)
    return {
      ...state,
      timesFromGuid,      
      timesFromGuidSelected: "20:30",
    };
    if (timesFromGuid === 21 * 60)
    return {
      ...state,
      timesFromGuid,      
      timesFromGuidSelected: "21:00",
    };
    if (timesFromGuid === 21.5 * 60)
    return {
      ...state,
      timesFromGuid,      
      timesFromGuidSelected: "21:30",
    };
    if (timesFromGuid === 22 * 60)
    return {
      ...state,
      timesFromGuid,      
      timesFromGuidSelected: "22:00",
    };
    if (timesFromGuid === 22.5 * 60)
    return {
      ...state,
      timesFromGuid,      
      timesFromGuidSelected: "22:30",
    };
    if (timesFromGuid === 23 * 60)
    return {
      ...state,
      timesFromGuid,      
      timesFromGuidSelected: "23:00",
    };
    if (timesFromGuid === 23.5 * 60)
    return {
      ...state,
      timesFromGuid,      
      timesFromGuidSelected: "23:30",
    };
    
    return {
      ...state,
      timesFromGuid,      
      timesFromGuidSelected: timesFromGuid,
    };
  },

  [BOOKING_TIMES_FROM_CLEAR] : (state) => {
    return {
      ...state,
      timesFromGuid: null
    }
  },

  [BOOKING_TIMES_TO] : (state, { timesToGuid } ) => {
    return {
      ...state,
      timesToGuid,
      timesToGuidSelected: timesToGuid,
    }
  },

  [BOOKING_TIMES_TO_CLEAR] : (state) => {
    return {
      ...state,
      timesToGuid: null
    }
  },
  [BOOKING_PEOPLE] : (state, { peopleGuid } ) => {
    return {
      ...state,
      peopleGuid,
      peopleGuidSelected: peopleGuid,
    }
  },

  [BOOKING_PEOPLE_CLEAR] : (state) => {
    return {
      ...state,
      peopleGuid: null
    }
  },
  

  [CATALOG_FILTER] : (state, { storyGuid } ) => {
    return {
      ...state,
      storyGuid,
      storyGuidSelected: storyGuid,
    }
  },

  [CATALOG_DRINK_FILTER] : (state, { storyDrinkGuid } ) => {
    return {
      ...state,
      storyDrinkGuid,
      storyDrinkGuidSelected: storyDrinkGuid,
    }
  },

  [CATALOG_TABLE_FILTER] : (state, { storyTableGuid } ) => {
    return {
      ...state,
      storyTableGuid,
      storyTableGuidSelected: storyTableGuid,
    }
  },

  [CATALOG_SEARCH] : (state, { search } ) => {
    const parts = (search?? "").toLowerCase().split(" ").filter(p => p.trim().length > 2)
    if (parts.length > 0) {
      const { catalogItems, stories } = state.data;
      const filteredCatalogItems = catalogItems.filter(item => item.price).filter(item =>
        parts.filter(p => (item.text ?? "").toLowerCase().includes(p) || 
          (item.textAlt ?? "").toLowerCase().includes(p) ||
          (item.description ?? "").toLowerCase().includes(p) || 
          (item.number ?? "").toLowerCase().includes(p) ||
          supportedLanguages.filter(lang => 
            (item[translatedPropertyName("text", lang)] ?? "").toLowerCase().includes(p) ||
            (item[translatedPropertyName("textAlt", lang)] ?? "").toLowerCase().includes(p) ||
            (item[translatedPropertyName("description", lang)] ?? "").toLowerCase().includes(p))
            .length > 0
          ).length === parts.length)
      const filteredStories = stories.filter(s =>
        filteredCatalogItems.filter(item => item.storyGuid === s.guid).length > 0);
      
      const storyGuidSelected = state.storyGuidSelected && 
        filteredStories.filter(s => s.guid === state.storyGuidSelected).length > 0 ? state.storyGuidSelected : 
        filteredStories[0]?.guid;

      return {
        ...state,
        dataFiltered: {
          stories: filteredStories,
          catalogItems: filteredCatalogItems
        },
        storyGuidSelected,
        search
      }
    } else {
      return {
        ...state,
        dataFiltered: null,
        storyGuidSelected: state.storyGuid,
        search
      }
    }
  },
 

  [CATALOG_FILTER_HEADER] : (state, { headerGuid }) => {
    return {
      ...state,
      headerGuid
    }
  },

  [CATALOG_DRINK_FILTER_HEADER] : (state, { headerDrinkGuid }) => {
    return {
      ...state,
      headerDrinkGuid
    }
  },

  [CATALOG_TABLE_FILTER_HEADER] : (state, { headerTableGuid }) => {
    return {
      ...state,
      headerTableGuid
    }
  },

  [CATALOG_ORDER] : (state, { guid, count, sum, currency, nameEmptyItem, price }) => {
    return {
      ...state,
      order: {
        ...state.order,
        [guid]: { count, sum, currency, nameEmptyItem, price } 
      }
    }
  },

  [ORDER_TABLES] : (state, { id, status, tabelNumber }) => {
    return {
      ...state,
      orderTables: {
        ...state.orderTables,
        [id]: { status, tabelNumber } 
      }
    }
  },

  [CATALOG_TABLE_ORDER] : (state, { guid, count, sum, currency }) => {
    return {
      ...state,
      order: {
        ...state.order,
        [guid]: { count, sum, currency } 
      }
    }
  },
  

  [CATALOG_ORDER_CLEAR] : (state) => {
    return {
      ...state,
      order: {}
    }
  },

  [ORDER_TABLES_CLEAR] : (state) => {
    return {
      ...state,
      orderTables: {}
    }
  },

    [CATALOG_TABLE_ORDER_CLEAR] : (state) => {
      return {
        ...state,
        order: {}
    }
  }

  
  
}, initialState);

export default reducer;
