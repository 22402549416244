import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { trackWindowScroll, LazyLoadComponent } from 'react-lazy-load-image-component';
import { useCookies } from 'react-cookie';
import { uuid } from 'uuidv4';
import CatalogItem from './CatalogItem';
import CatalogItemSettings from './CatalogItemSettings';
import Slider from 'components/common/Slider';
import { __ } from 'utils/translation';
import { SCROLL_CATALOG_ID } from 'constants/scrolls';
import './index.scss';

const CatalogBottles = ({ data, profile, scrollPosition }) => {
  const [settingsOpened, setSettingsOpened] = useState(null);
  const [catalogItemData, setCatalogItemData] = useState({});
  const [cookies] = useCookies();
  const { catalogBottles } = useSelector((state) => state.config.data);
  const { storiesBottles } = useSelector((state) => state.config.data);
  const { storyGuidSelected, headerGuid, dataFiltered } = useSelector((state) => state.config);

  const closeCatalogItemsSettings = () => {
    setSettingsOpened(null);
  };

  const onOpenCatalogItemSettings = (catalogItemId) => {
    setSettingsOpened(catalogItemId);
  };

  var inviteId = cookies[profile];
  if (inviteId === "undefined") {
    inviteId = null;
  }

  useEffect(() => {
    const currentCatalogItem = data.find((catalogItem) => catalogItem.guid === settingsOpened);
    const settings = currentCatalogItem ;    
    setCatalogItemData({ ...settings });
  }, [settingsOpened, data]);

  const hour = new Date().getHours();
  const checkCatalogItem = (e) => {
    return (storyGuidSelected || (storiesBottles?.length ?? 0) === 0)
      && (!storyGuidSelected || !e.storyGuid || e.storyGuid === storyGuidSelected)
      && !e.outOfStock
      && ((!e.timeFrom && !e.timeTo) ||
        (e.timeFrom && e.timeTo && parseInt(e.timeFrom) <= hour && parseInt(e.timeTo) >= hour) ||
        (e.timeFrom && e.timeTo && parseInt(e.timeFrom) > parseInt(e.timeTo) && (parseInt(e.timeFrom) <= hour || parseInt(e.timeTo) <= hour)) ||
        (e.timeFrom && !e.timeTo && parseInt(e.timeFrom) <= hour) ||
        (!e.timeFrom && e.timeTo && parseInt(e.timeTo) >= hour))
      && (!e.headerGuid || dataFiltered || e.headerGuid === headerGuid);
  }

  // if (!inviteId && !active) { 
  //   return null;  
  // }

  data.sort((a, b) => b.order - a.order);
  return (
    <React.Fragment>
      {catalogBottles.length > 0 &&
        <div id={SCROLL_CATALOG_ID}>{
          data.filter(checkCatalogItem).map((catalogItem, index) =>
            <LazyLoadComponent key={catalogItem.guid} index={index} scrollPosition={scrollPosition} threshold={10}>
              <CatalogItem
                key={catalogItem.guid}
                index={index}
                {...catalogItem}
                scrollPosition={scrollPosition}
                onClick={catalogItem.price || catalogItem.number ? (() => onOpenCatalogItemSettings(catalogItem.guid)) : null} />
            </LazyLoadComponent>)
        }</div>
      }
      <div className="empty-catalogItems-box"></div>
      <Slider
        opened={settingsOpened}
        onClose={closeCatalogItemsSettings}
        onSubmit={closeCatalogItemsSettings}
        submitTitle={__("ЗАКРЫТЬ")?.toUpperCase()}
      >
        <CatalogItemSettings {...catalogItemData} />
      </Slider>
    </React.Fragment>
  ); 
};

CatalogBottles.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({}))
};

CatalogBottles.defaultProps = {
  data: []
};

export default trackWindowScroll(CatalogBottles);
