import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { __ } from 'utils/translation';
import Button from 'components/common/Button';
import Icon from 'components/common/Icon';
import { getSearchParams } from 'utils/url';
import './index.scss';

import {
  CATALOG_LEFT,
  CATALOG_CENTER,
  CATALOG_RIGHT,
  CATALOG_HEADER,
} from 'constants/catalogTypes';

import { CATALOG_ORDER, CATALOG_FILTER_HEADER } from 'constants/actions';
import { translatedProperty } from 'utils/translation';

const CatalogItem = ({
  guid,
  animation,
  image,
  price,
  currency,
  number,
  type,
  text,
  textEn,
  textRu,
  textEs,
  textIt,
  textDe,
  textFr,
  textZh,
  textAlt,
  textAltEn,
  textAltRu,
  textAltEs,
  textAltIt,
  textAltDe,
  textAltFr,
  textAltZh,
  onClick,
  className,
  technical,
  index,
  reviews
}) => {
  const dispatch = useDispatch();
  const { count, sum } = useSelector((state) => state.config.order[guid] ?? { count: 0, sum: 0 });
  const { headerGuid } = useSelector((state) => state.config);

  const translatedText = translatedProperty({ text, textEn, textEs, textRu, textDe, textFr, textIt, textZh }, "text");
  const translatedTextAlt = translatedProperty({ textAlt, textAltEn, textAltEs, textAltRu, textAltDe, textAltFr, textAltIt, textAltZh }, "textAlt");

  const handleHeaderClick = () => {
    dispatch({ type: CATALOG_FILTER_HEADER, headerGuid: headerGuid === guid ? null : guid });
  }

  var params = getSearchParams(window.location.search);

  let isWaiter = false;
  if (params['waiter'] === "" || params['waiter'])  {
    isWaiter = true;
  };

  const nameEmptyItem = "";
  const handlePlus = (e) => {
    e.stopPropagation();
    dispatch({
      type: CATALOG_ORDER, guid, count: count + 1,
      sum: sum + parseFloat(price), currency, nameEmptyItem
    });
  };
  const handleMinus = (e) => {
    e.stopPropagation();
    if (count === 0) {
      return;
    }
    dispatch({
      type: CATALOG_ORDER, guid, count: count - 1,
      sum: sum - parseFloat(price), currency,nameEmptyItem
    });
  };

  const handleChecked = (e) => {
    if (count === 0) {
      handlePlus(e);
    } else {
      handleMinus(e);
    }
  }


  const sumValue = count !== 0 ? sum.toFixed(2) :
    parseFloat(price).toFixed(2);


  const reviewImage = (reviews?.filter(r => r.isActive) ?? []).reverse().filter(r => r.image)[0]?.image;
  const imageCard = image ?? reviewImage;

  switch (type) {
    case CATALOG_LEFT:
    default: {
      const style = {};
      if ((imageCard) && (!price & !number))
        style.backgroundImage = `URL(${imageCard})`
      const catalogItem = (
        <div
          className={classnames([
            'catalogItem',
            'catalogItem__left',
            { 'catalogItem__left__with-image': imageCard },
            { 'catalogItem__left__with-button': (price || number) },
            { 'catalogItem__left__with-image__with-button': (price || number) },
            { 'catalogItem-withimage-wihout-title-left': imageCard && (!price & !number & !text) },
            className
          ])}
          style={style}
          key={guid}
          onClick={onClick}
        >
          {imageCard && (price || number) && (
            <img src={imageCard} alt={translatedText} />
          )}
          {(!price && !number) && translatedText && (
            <div className="catalogItem-preorder-flex-column">
              <div className="catalogItem-left-title-without-button">{translatedText}</div>
            </div>
          )}
          {(translatedText && !translatedTextAlt) && (price || number) && (
            <div className="catalogItem-preorder-flex-column">
              <div className="catalogItem__title">{translatedText}</div>
            </div>
          )}
          {(translatedText && translatedTextAlt) && (price || number) && (
            <div className="catalogItem-preorder-flex-column">
              <div className="catalogItem-price-empty"></div>
              <div className="catalogItem__title">{translatedText}</div>
              <div className="catalogItem-text-en">{translatedTextAlt}</div>
            </div>
          )}

          {(price || number) && (
            <div className="catalogItem-preorder-flex-column">
              {!isWaiter && <>
                <div className="catalogItem-price-empty"></div>
                <div className="catalogItem-preorder-flex-row">
                  <Button isInline noStyled onClick={handleMinus} ><Icon type="MinusCircle" className="catalogItem-add-button" /> </Button>
                  <div className="catalogItem-quantity">{count}</div>
                  <Button isInline noStyled onClick={handlePlus} ><Icon type="plusCircle" className="catalogItem-add-button" /> </Button>
                </div>
              </>}
              {price && <div className="catalogItem-price-currency">{sumValue}&nbsp;{currency}</div>}
              {!price && <div className="catalogItem-price-empty"></div>}
            </div>
          )}

        </div>
      );

      if ((price || number) && index === 0)
        return (
          <div >
            <Button className="button-in-catalogItem-left " isPulse={true} technical={technical}>
              <div className="story-clickme">{__("Нажми")}</div>
              {catalogItem}
            </Button>
          </div>
        );

        if ((price || number) && index !== 0)
        return (
          <div >
            <Button className="button-in-catalogItem-left " isPulse={animation} technical={technical}>
              {catalogItem}
            </Button>
          </div>
        );

      return catalogItem;
    }

    case CATALOG_HEADER: {
      const style = {};
      if (imageCard)
        style.backgroundImage = `URL(${imageCard})`;

      const catalogItem = (
        <div
          className={classnames([
            'catalogItem',
            'catalogItem__center',
            { 'catalogItem__center__with-image': imageCard },
            { 'catalogItem__center__with-image__without-button': (price || number) },
            { 'catalogItem__center__with-image__without-button': imageCard && (price || number) },
            { 'catalogItem__center__with-image__without-button': imageCard && (!price & !number) },
            { 'catalogItem-withimage-wihout-title-center': imageCard && (!price & !number & !text) },
            className
          ])}
          style={style}
          key={guid}
          onClick={handleHeaderClick}
        >
          <div className="catalogItem__title">{translatedText}<br /><Icon type={headerGuid ? "angleDoubleUp" : "angleDoubleDown"} /></div>
        </div>
      );
      return catalogItem;
    }

    case CATALOG_CENTER: {
      const style = {};
      if (imageCard)
        style.backgroundImage = `URL(${imageCard})`;

      const catalogItem = (
        <div
          className={classnames([
            'catalogItem',
            'catalogItem__center',
            { 'catalogItem__center__with-image': imageCard },
            { 'catalogItem__center__with-button': (price || number) },
            { 'catalogItem__center__with-image__with-button': imageCard && (price || number) },
            { 'catalogItem__center__with-image__without-button': imageCard && (!price & !number) },
            { 'catalogItem-withimage-wihout-title-center': imageCard && (!price & !number & !text) },
            className
          ])}
          style={style}
          key={guid}
          onClick={onClick}
        >
          <div> {count > 0 && <div className="catalogItem-number-item">{count}</div>} </div>
          <div className="catalogItem__title">{translatedText}
            {(price || number) && (

              <div className="catalogItem-preorder-flex-column-center">
                {price && <div className="catalogItem-price-currency">{sumValue}&nbsp;{currency}</div>}
                {!price && <div className="catalogItem-price-empty"></div>}
              </div>
            )}
          </div>
        </div>
      );

      if (price || number)
        return (
          <div >
            <Button className="button-in-catalogItem-center " isPulse={animation} technical={technical}>
              {catalogItem}
            </Button>
          </div>
        );
      return catalogItem;
    }

    case CATALOG_RIGHT: {
      const style = {};
      if ((imageCard) && (!price & !number))
        style.backgroundImage = `URL(${imageCard})`;
      const catalogItem = (
        <div
          className={classnames([
            'catalogItem',
            'catalogItem__right',
            { 'catalogItem__right__with-image': imageCard },
            { 'catalogItem__right__with-button': (price || number) },
            { 'catalogItem__right__with-image__with-button': imageCard && (price || number) },
            { 'catalogItem-withimage-wihout-title-right': imageCard && (!price & !number & !text) },
            className
          ])}
          style={style}
          key={guid}
          onClick={onClick}
        >
          {imageCard && (price || number) && (
            <img src={imageCard} alt={translatedText} />
          )}
          {(!price && !number) && translatedText && (
            <div className="catalogItem-preorder-flex-column">
              <div className="catalogItem-right-title-without-button">{translatedText}</div>
            </div>
          )}
          {(translatedText) && (price || number) && (
            <div className="catalogItem-preorder-flex-column">

              <div className="catalogItem__title">{translatedText}</div>
              {translatedTextAlt && <div className="catalogItem-text-en">{translatedTextAlt}</div>}
            </div>
          )}

          {(price || number) && price && (
            <div className="catalogItem-preorder-flex-column">

              <div className="catalogItem-preorder-flex-row">
                <div className="catalogItem-price-currency-right">{price}&nbsp;{currency}</div>
                <Button isInline noStyled onClick={handleChecked} >
                  {count === 0 ?
                    <Icon type="plusCircle" className="catalogItem-add-button" />
                    : <Icon type="check" className="catalogItem-check-button" />
                  }
                </Button>
              </div>
            </div>
          )}

        </div>
      );

      if (price || number)
        return (
          <div >
            <Button className="button-in-catalogItem-right " isPulse={animation} technical={technical}>
              {catalogItem}
            </Button>
          </div>
        );
      return catalogItem;
    }
  }
};

CatalogItem.propTypes = {
  guid: PropTypes.string,
  animation: PropTypes.bool,
  image: PropTypes.string,
  text: PropTypes.string,
  textEn: PropTypes.string,
  price: PropTypes.number,
  currency: PropTypes.string,
  number: PropTypes.number,
  outOfStock: PropTypes.bool,
  type: PropTypes.string,
  technical: PropTypes.bool,
  refObj: PropTypes.object
};

CatalogItem.defaultProps = {
  animation: false,
  image: undefined,
  text: '',
  textEn: '',
  price: undefined,
  currency: '',
  number: undefined,
  outOfStock: false,
  type: undefined,
  technical: false,
  refObj: undefined
};

export default CatalogItem;
