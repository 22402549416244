import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { trackWindowScroll } from 'react-lazy-load-image-component';
import { useCookies } from 'react-cookie';
import Story from 'components/Stories/Story';
import SearchComponent from 'components/SearchComponent';
import { __ } from 'utils/translation';
import addedMenu from 'images/desserts.jpg';
import { CATALOG_FILTER, CATALOG_SEARCH } from 'constants/actions';

import './index.scss';
import 'components/Stories/index.scss';

const Stories = ({ data, profile, scrollPosition }) => {
  const [cookies] = useCookies();


  const { stories = [] } = useSelector((state) => state.config.data);
  const { catalogItems = [] } = useSelector((state) => state.config.data);
  const { storyGuid, storyGuidSelected, search } = useSelector((state) => state.config);
  const dispatch = useDispatch();

  useEffect(() => {
    if (stories.length > 0) { 
    const stories0 = stories[0];
    const storyId = stories0.guid
    if (catalogItems.filter(e => e.storyGuid === storyId).length > 0) {
      dispatch({ type: CATALOG_FILTER, storyGuid: storyGuid !== storyId ? storyId : null });
    };
  };
}, []);

const handleStoryClick = (storyId) => {
  if (catalogItems.filter(e => e.storyGuid === storyId).length > 0) {
    dispatch({ type: CATALOG_FILTER, storyGuid: storyGuid !== storyId ? storyId : null });
  };
};

const handleSearch = (value) => {
  dispatch({ type: CATALOG_SEARCH, search: value });
};

var inviteId = cookies[profile];
if (inviteId === "undefined") {
  inviteId = null;
};

const placeholder = __("Поиск десерта");

stories.sort((a, b) => b.order - a.order);

return (
  <React.Fragment>

    {catalogItems.length > 0 && stories.length > 1 &&
      <div className="stories">
        {/* <SearchComponent search={search} placeholder={placeholder} onSearch={handleSearch} /> */}
        <div className="stories-picker">
          {data.map((story, index) =>
            <Story className={classnames([`stories-picker-item`])}
              onClick={() => handleStoryClick(story.guid)}
              index={index}
              key={story.guid} {...story}
              selected={storyGuidSelected === story.guid}
              scrollPosition={scrollPosition} />)
          }
        </div>
      </div>
    }

  </React.Fragment>
);
};

Stories.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({}))
};

Stories.defaultProps = {
  data: []
};

export default trackWindowScroll(Stories);
